import { IconButton } from "@mui/material";
import {
  CoPresent as CoPresentIcon,
  People as PeopleIcon,
  ChevronLeft as ChevronLeftIcon,
  MenuBook as MenuBookIcon,
  Assessment as AssessmentIcon,
  AccountCircle as AccountCircleIcon,
  Print as PrintIcon,
  CheckCircle as CheckCircleIcon,
  Lan as LanIcon,
  AccountTree as AccountTreeIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Place as PlaceIcon,
  MyLocation as MyLocationIcon,
  AccountBalance as AccountBalanceIcon,
  ListAlt as ListAltIcon,
  LocalHospital as LocalHospitalIcon,
  AttachMoney as AttachMoneyIcon,
  CalendarToday as CalendarTodayIcon,
  Payment as PaymentIcon,
} from "@mui/icons-material";

import DrawerHeader from "./DrawerHeader";
import DrawerSideMenu from "./DrawerSideMenu";
import StyledDrawer from "./StyledDrawer";
import { URL_PATH } from "../../constants";

const menus = [
  {
    text: "Organisasi",
    icon: <AccountTreeIcon />,
    role: "admin",
    children: [
      {
        text: "Super Admin",
        icon: <AdminPanelSettingsIcon />,
        role: "owner",
        path: URL_PATH.ORGANIZATION.SUPER_ADMIN,
      },
      {
        text: "Karyawan",
        icon: <CoPresentIcon />,
        role: "owner",
        path: URL_PATH.ORGANIZATION.EMPLOYEE,
      },
      {
        text: "Anggota",
        icon: <PeopleIcon />,
        role: "admin",
        path: URL_PATH.ORGANIZATION.MEMBER,
      },
      {
        text: "Kota",
        icon: <PlaceIcon />,
        role: "owner",
        path: URL_PATH.ORGANIZATION.CITY,
      },
      {
        text: "Area",
        icon: <MyLocationIcon />,
        role: "owner",
        path: URL_PATH.ORGANIZATION.AREA,
      },
      {
        text: "Server",
        icon: <PaymentIcon />,
        role: "owner",
        path: URL_PATH.ORGANIZATION.SERVER_PAYMENT,
      },
    ],
  },
  {
    text: "Pinjaman",
    icon: <AttachMoneyIcon />,
    role: "employee",
    children: [
      {
        text: "Pengajuan",
        icon: <LanIcon />,
        role: "admin",
        path: URL_PATH.LOAN.REQUEST,
      },
      {
        text: "Rekapitulasi",
        icon: <MenuBookIcon />,
        role: "employee",
        path: URL_PATH.LOAN.RECAPITULATION,
      },
      {
        text: "Laporan",
        icon: <PrintIcon />,
        role: "employee",
        path: URL_PATH.LOAN.REPORT,
      },
      {
        text: "Rangkuman",
        icon: <CalendarTodayIcon />,
        role: "admin",
        path: URL_PATH.LOAN.SUMMARY,
      },
      {
        text: "Selesai",
        icon: <CheckCircleIcon />,
        role: "admin",
        path: URL_PATH.LOAN.FINISHED,
      },
    ],
  },
  {
    text: "Simpanan",
    icon: <AccountBalanceIcon />,
    role: "admin",
    children: [
      {
        text: "Detail",
        icon: <ListAltIcon />,
        role: "admin",
        path: URL_PATH.SAVING.DETAIL,
      },
      {
        text: "Pengajuan",
        icon: <LanIcon />,
        role: "admin",
        path: URL_PATH.SAVING.REQUEST,
      },
      {
        text: "Laporan",
        icon: <PrintIcon />,
        role: "admin",
        path: URL_PATH.SAVING.REPORT,
      },
    ],
  },
  {
    text: "Asuransi",
    icon: <LocalHospitalIcon />,
    role: "admin",
    children: [
      {
        text: "Pengajuan",
        icon: <LanIcon />,
        role: "admin",
        path: URL_PATH.INSURANCE.REQUEST,
      },
      {
        text: "Laporan",
        icon: <PrintIcon />,
        role: "admin",
        path: URL_PATH.INSURANCE.REPORT,
      },
    ],
  },
  {
    text: "Analitik",
    icon: <AssessmentIcon />,
    role: "owner",
    path: URL_PATH.ANALYTIC,
  },
  {
    text: "Akun Saya",
    icon: <AccountCircleIcon />,
    role: "employee",
    path: URL_PATH.MY_ACCOUNT,
  },
];

const Drawer = ({ isOpen, handleOpenDrawer, handleCloseDrawer }) => {
  return (
    <StyledDrawer
      variant="permanent"
      isOpen={isOpen}
      onMouseEnter={handleOpenDrawer}
      onMouseLeave={handleCloseDrawer}
    >
      <DrawerHeader>
        <IconButton onClick={handleCloseDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerSideMenu menus={menus} />
    </StyledDrawer>
  );
};

export default Drawer;
