import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  List,
  ListItem,
  Typography,
  Divider,
} from "@mui/material";
import HighlightWord from "../HighlightWord";
import { CITY } from "../../constants";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

const NotificationModal = ({ isOpen, setIsOpen, data }) => {
  const { cities } = useContext(AuthContext);

  const handleClose = () => {
    setIsOpen(false);
  };

  const getNotificationList = (data, title) => {
    if (!data.length) return <></>;

    return (
      <>
        <Typography>
          <HighlightWord>{title}</HighlightWord>
        </Typography>
        <List title={title}>
          {data.map((el, idx) => (
            <ListItem key={idx} sx={{ display: "list-item" }}>
              {cities.length &&
                cities.find((city) => city.id === el.cityId).name}{" "}
              (Belum diselesaikan)
            </ListItem>
          ))}
        </List>
        <Divider sx={{ marginBottom: 1 }} />
      </>
    );
  };

  const getNotificationLock = (data, title) => {
    if (!data.length) return <></>;
    const counter = {};
    for (const key in CITY) {
      const cityId = CITY[key];
      counter[cityId] = data.filter(
        (el) => el.bill.user.cityId === cityId
      ).length;
    }

    return (
      <>
        <Typography>
          <HighlightWord>{title}</HighlightWord>
        </Typography>
        <List title={title}>
          {Object.entries(counter)
            .filter(([cityId, total]) => total > 0)
            .map(([cityId, total], idx) => (
              <ListItem key={idx} sx={{ display: "list-item" }}>
                {cities.length &&
                  cities.find((city) => city.id === parseInt(cityId)).name}{" "}
                ({total} orang sudah tidak bisa dikunci)
              </ListItem>
            ))}
        </List>
        <Divider sx={{ marginBottom: 1 }} />
      </>
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <HighlightWord color="red">PERINGATAN!!!</HighlightWord>
        </DialogTitle>
        <Box component="form">
          <DialogContent>
            <DialogContentText sx={{ marginBottom: 4, marginTop: -2 }}>
              Ada proses yang belum diselesaikan. Segera menyelesaikan proses
              tersebut melakukan aksi yang lain karena dapat menyebabkan error
              jika dibiarkan.
            </DialogContentText>
            <Box>
              <Divider />
              {getNotificationList(data.bill_schedule_daily, "Tagihan Harian")}
              {getNotificationList(
                data.bill_schedule_monthly,
                "Tagihan Bulanan"
              )}
              {getNotificationList(
                data.bill_request_daily,
                "Pengajuan Tagihan Harian"
              )}
              {getNotificationList(
                data.bill_request_monthly,
                "Pengajuan Tagihan Bulanan"
              )}
              {getNotificationLock(
                data.lock_monthly_bill_transaction,
                "Tagihan Bulanan Belum Terkunci"
              )}
              {getNotificationList(
                data.insurance_request,
                "Pengajuan Asuransi"
              )}
              {getNotificationList(data.saving_request, "Pengajuan Simpanan")}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="outlined">
              Saya Mengerti!
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default NotificationModal;
