import { useContext, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  AppBar as AppBarMui,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  styled,
  Tooltip,
  MenuItem,
  Menu,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Menu as MenuIcon,
  Logout as LogoutIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";

import AuthContext from "../context/AuthContext";
import { BILL_TYPE, DRAWER_WIDTH, ROLE, URL_PATH } from "../constants";
import axios from "../axios";
import { useGetCityName } from "../hooks/useGetCity";

const AppBar = styled(AppBarMui, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isOpen && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ handleOpenDrawer, isOpen }) => {
  const {
    setUser,
    setAccessToken,
    user,
    accessToken,
    billType,
    setBillType,
    cities,
    queryAreas,
  } = useContext(AuthContext);
  const cityName = useGetCityName();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElBillType, setAnchorElBillType] = useState(null);
  const isOpenMenu = Boolean(anchorEl);
  const isOpenMenuBillType = Boolean(anchorElBillType);
  const [_, setSearchParams] = useSearchParams({});

  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (input) => {
      const { data } = await axios({
        method: "PATCH",
        url: `/users/${input.userId}/city`,
        data: { cityId: input.cityId },
        headers: {
          accessToken: accessToken || localStorage.getItem("accessToken"),
        },
      });

      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("checkHasBeenScheduled");
        queryClient.invalidateQueries(queryAreas);
        const newUser = { ...user, cityId: data.cityId };
        setUser(newUser);
        localStorage.setItem("user", JSON.stringify(newUser));
        navigate("/");
      },
    }
  );

  const handleClickTitle = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("billType");
    localStorage.removeItem("_grecaptcha");

    setUser(null);
    setAccessToken("");
    navigate("/login", { replace: true });
  };

  const handleInfo = () => {
    navigate("/tentang-aplikasi");
  };

  const getBillType = (billType) => {
    switch (billType) {
      case BILL_TYPE.DAILY:
        return "HARIAN";
      case BILL_TYPE.MONTHLY:
        return "BULANAN";
      default:
        return "HARIAN";
    }
  };

  const handleClickButtonCity = (e, user) => {
    if (user.role !== ROLE.OWNER) return;
    setAnchorEl(e.currentTarget);
  };

  const handleClickButtonBillType = (e, user) => {
    if (![ROLE.OWNER, ROLE.ADMIN].includes(user.role)) return;
    setAnchorElBillType(e.currentTarget);
  };

  const handleChangeCity = async (e, user) => {
    const data = { userId: user.id, cityId: e.target.value };
    await mutation.mutateAsync(data);
    setAnchorEl(null);
  };

  const handleChangeBillType = async (e) => {
    setSearchParams({});
    setBillType(e.target.value);
    setAnchorElBillType(null);
    localStorage.setItem("billType", e.target.value);
  };

  const checkDisableButtonBillType = (location) => {
    const disabledURLS = [
      "/tentang-aplikasi",
      URL_PATH.MY_ACCOUNT,
      URL_PATH.LOAN.FINISHED,
      ...Object.values(URL_PATH.ORGANIZATION),
      ...Object.values(URL_PATH.SAVING),
      ...Object.values(URL_PATH.INSURANCE),
    ];

    return disabledURLS.includes(location.pathname);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" isOpen={isOpen}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              marginRight: (theme) => theme.spacing(1),
              ...(isOpen && { display: "none" }),
            }}
            onClick={() => handleOpenDrawer()}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={handleClickTitle}
          >
            KSP Tiga Warna
          </Typography>

          {/* right side */}
          {/* cityId */}
          <LoadingButton
            variant="outlined"
            onClick={(e) => handleClickButtonCity(e, user)}
            id="menu-select-city"
            aria-controls={isOpenMenu ? "menu-select-city" : undefined}
            aria-haspopup="true"
            aria-expanded={isOpenMenu ? "true" : undefined}
            loading={mutation.isLoading}
            sx={{
              borderColor: "white",
              color: "#fff",
              ":hover": {
                borderColor: "white",
                color: "#fff",
              },
              marginRight: "10px",
            }}
          >
            {cityName}
          </LoadingButton>
          <Menu
            open={isOpenMenu}
            anchorEl={anchorEl}
            id="menu-select-city"
            aria-labelledby="menu-select-city"
            onClose={() => setAnchorEl(null)}
          >
            {cities.map((city) => (
              <MenuItem
                key={city.id}
                onClick={(e) => handleChangeCity(e, user)}
                value={city.id}
              >
                {city.name}
              </MenuItem>
            ))}
          </Menu>

          {/* billType */}
          <LoadingButton
            variant="outlined"
            onClick={(e) => handleClickButtonBillType(e, user)}
            id="menu-select-bill-type"
            aria-controls={
              isOpenMenuBillType ? "menu-select-bill-type" : undefined
            }
            aria-haspopup="true"
            aria-expanded={isOpenMenuBillType ? "true" : undefined}
            sx={{
              borderColor: "white",
              color: "#fff",
              ":hover": {
                borderColor: "white",
                color: "#fff",
              },
            }}
            disabled={checkDisableButtonBillType(location)}
          >
            {getBillType(billType)}
          </LoadingButton>
          <Menu
            open={isOpenMenuBillType}
            anchorEl={anchorElBillType}
            id="menu-select-bill-type"
            aria-labelledby="menu-select-bill-type"
            onClose={() => setAnchorElBillType(null)}
          >
            <MenuItem onClick={handleChangeBillType} value={BILL_TYPE.DAILY}>
              HARIAN
            </MenuItem>
            <MenuItem onClick={handleChangeBillType} value={BILL_TYPE.MONTHLY}>
              BULANAN
            </MenuItem>
          </Menu>

          {/* info & logout */}
          <Tooltip title="Info Aplikasi">
            <Button color="inherit" onClick={handleInfo}>
              <InfoIcon />
            </Button>
          </Tooltip>
          <Button color="inherit" onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 1 }} />
            Keluar
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Navbar;
