import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";

import Navbar from "./Navbar";
import Drawer from "./Drawer/Drawer";
import DrawerHeader from "./Drawer/DrawerHeader";
import axios from "../axios";
import { useQuery } from "react-query";
import AuthContext from "../context/AuthContext";
import LoadingSpinner from "./LoadingSpinner";

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { accessToken, setCities, setAreas, queryAreas, queryCities } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const handleOpenDrawer = () => setIsOpen(true);
  const handleCloseDrawer = () => setIsOpen(false);

  const { isLoading: isLoadingCities } = useQuery(
    [queryCities],
    async () => {
      const { data } = await axios({
        method: "GET",
        url: "/cities",
        headers: {
          accessToken: accessToken || localStorage.getItem("accessToken"),
        },
      });

      return data.data;
    },
    {
      retry: 0,
      onSuccess: (data) => {
        setCities(data);
      },
    }
  );

  const { isLoading: isLoadingAreas } = useQuery(
    [queryAreas],
    async () => {
      const { data } = await axios({
        method: "GET",
        url: "/areas",
        headers: {
          accessToken: accessToken || localStorage.getItem("accessToken"),
        },
      });

      return data.data;
    },
    {
      retry: 0,
      onSuccess: (data) => {
        setAreas(data);
      },
    }
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }

    const checkAccessToken = async () => {
      try {
        const { data } = await axios({
          method: "GET",
          url: "/users/detail",
          headers: { accessToken },
        });

        if (!data.user.hasChangedDefaultPassword) {
          navigate("/akun-saya");
        }
      } catch (err) {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      }
    };

    checkAccessToken();
  }, [navigate]);

  return (
    <>
      <Navbar handleOpenDrawer={handleOpenDrawer} isOpen={isOpen} />
      <Box sx={{ display: "flex" }}>
        <Drawer
          isOpen={isOpen}
          handleOpenDrawer={handleOpenDrawer}
          handleCloseDrawer={handleCloseDrawer}
          sx={{ border: "blue 1px solid" }}
        />
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Container
            fluid="true"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isLoadingCities || isLoadingAreas ? (
              <LoadingSpinner />
            ) : (
              <Outlet />
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
