import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
} from "@mui/material";
import HighlightWord from "../HighlightWord";
import { ROLE, URL_PATH } from "../../constants";
import { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { formatCurrency, parseCurrencyFromInput } from "../../utils";
import { useMutation } from "react-query";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";

const ServerPaymentModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const { user, accessToken } = useContext(AuthContext);
  const [isShowExtended, setIsShowExtended] = useState(false);
  const [formattedPayment, setFormattedPayment] = useState(formatCurrency(0));
  const [payment, setPayment] = useState(0);

  const mutation = useMutation(
    async (patchData) => {
      const { data } = await axios({
        method: "POST",
        url: `/server-payments`,
        headers: { accessToken },
        data: patchData,
      });

      return data.data;
    },
    { onSuccess: () => navigate(URL_PATH.ORGANIZATION.SERVER_PAYMENT) }
  );

  const handleClose = () => {
    setIsOpen(false);
    setIsShowExtended(false);
    setFormattedPayment(formatCurrency(0));
    setPayment(0);
  };

  const handleShowExtended = () => {
    setIsShowExtended(true);
  };

  const handlePaymentChange = (e) => {
    const currency = parseCurrencyFromInput(e.target.value);
    setPayment(currency);
    setFormattedPayment(formatCurrency(currency));
  };

  const handlePayment = async () => {
    await mutation.mutateAsync({ total: payment });
    handleClose();
  };

  const getButtonPayment = () => {
    if (isShowExtended) {
      return (
        <Button onClick={handlePayment} color="success" variant="outlined">
          Simpan
        </Button>
      );
    }

    return (
      <Button onClick={handleShowExtended} color="primary" variant="outlined">
        Saya Telah Membayar
      </Button>
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <HighlightWord color="red">PEMBAYARAN SERVER!!!</HighlightWord>
        </DialogTitle>
        <Box component="form">
          <DialogContent>
            <DialogContentText sx={{ marginBottom: 4, marginTop: -2 }}>
              Server belum dibayar bulan ini. Segera hubungi ketua Koperasi Tiga
              Warna untuk segera melunasi pembayaran{" "}
              <HighlightWord color="red">
                sebelum tanggal 15 bulan ini
              </HighlightWord>
              . Jika tidak, server tidak akan bisa diakses. Terima kasih.
            </DialogContentText>
            {isShowExtended && (
              <TextField
                autoFocus
                required
                fullWidth
                id="total"
                name="total"
                type="text"
                label="Total Bayar"
                variant="standard"
                sx={{ width: "100%" }}
                onChange={handlePaymentChange}
                value={formattedPayment}
                autoComplete="off"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="outlined">
              Tutup
            </Button>
            {user.role === ROLE.OWNER && getButtonPayment()}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ServerPaymentModal;
