import { Avatar, TextField, Paper, Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  LockOutlined as LockOutlinedIcon,
  Login as LoginIcon,
} from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = ({ handleSubmit, isLoading, setCaptchaValue }) => {
  const onChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main", marginBottom: "20px" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ marginBottom: "10px" }}>
          KSP Tiga Warna Parepare
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <ReCAPTCHA
            sitekey="6LfGRYweAAAAAEPzA9YUdosW9yVdvh7AnMqJy9l6"
            onChange={onChange}
            hl="id"
          />
          <LoadingButton
            type="submit"
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            fullWidth
            endIcon={<LoginIcon />}
            sx={{ mt: 3, mb: 2 }}
          >
            Masuk
          </LoadingButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default LoginForm;
