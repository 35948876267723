import { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";

import AuthContext from "./context/AuthContext";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { BILL_TYPE, URL_PATH } from "./constants";

const Owner = lazy(() => import("./pages/Organizations/Owner"));
const Employee = lazy(() => import("./pages/Organizations/Employee"));
const Member = lazy(() => import("./pages/Organizations/Member"));
const DetailMember = lazy(() => import("./pages/DetailMember"));
const Recapitulation = lazy(() => import("./pages/Loans/Recapitulation"));
const Analytic = lazy(() => import("./pages/Analytic"));
const Profile = lazy(() => import("./pages/Profile"));
const About = lazy(() => import("./pages/About"));
const Report = lazy(() => import("./pages/Loans/Report"));
const NotFound = lazy(() => import("./pages/NotFound"));
const CompletedBill = lazy(() => import("./pages/Loans/CompletedBill"));
const Submission = lazy(() => import("./pages/Loans/Submission"));
const DailySummary = lazy(() => import("./pages/Loans/DailySummary"));
const City = lazy(() => import("./pages/Organizations/City"));
const Area = lazy(() => import("./pages/Organizations/Area"));
const SavingDetail = lazy(() => import("./pages/Savings/Detail"));
const SavingRequest = lazy(() => import("./pages/Savings/Request"));
const SavingReport = lazy(() => import("./pages/Savings/Report"));
const InsuranceRequest = lazy(() => import("./pages/Insurances/Request"));
const InsuranceReport = lazy(() => import("./pages/Insurances/Report"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));
const ServerPayment = lazy(() => import("./pages/Organizations/ServerPayment"));

const queryClient = new QueryClient();

const App = () => {
  const theme = createTheme();
  const [accessToken, setAccessToken] = useState("");
  const [user, setUser] = useState(null);
  const [billType, setBillType] = useState(BILL_TYPE.DAILY);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("verifikasi-email")) {
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    const billType = localStorage.getItem("billType");

    if (accessToken && user) {
      setAccessToken(accessToken);
      setUser(JSON.parse(user));
      setBillType(billType ? parseInt(billType) : BILL_TYPE.DAILY);
      return;
    }

    navigate("/login");
  }, [navigate, location.pathname]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <AuthContext.Provider
            value={{
              accessToken,
              setAccessToken,
              user,
              setUser,
              billType,
              setBillType,
              cities,
              setCities,
              areas,
              setAreas,
              queryAreas: "getAreas",
              queryCities: "getCities",
            }}
          >
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                {/* ===== ORGANIZATION ===== */}
                <Route
                  path={URL_PATH.ORGANIZATION.SUPER_ADMIN}
                  element={
                    <Suspense fallback={<></>}>
                      <Owner />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.ORGANIZATION.EMPLOYEE}
                  element={
                    <Suspense fallback={<></>}>
                      <Employee />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.ORGANIZATION.MEMBER}
                  element={
                    <Suspense fallback={<></>}>
                      <Member />
                    </Suspense>
                  }
                />
                <Route
                  path="/anggota/detail/:id"
                  element={
                    <Suspense fallback={<></>}>
                      <DetailMember />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.ORGANIZATION.CITY}
                  element={
                    <Suspense fallback={<></>}>
                      <City />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.ORGANIZATION.AREA}
                  element={
                    <Suspense fallback={<></>}>
                      <Area />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.ORGANIZATION.SERVER_PAYMENT}
                  element={
                    <Suspense fallback={<></>}>
                      <ServerPayment />
                    </Suspense>
                  }
                />
                {/* ===== LOAN ===== */}
                <Route
                  path={URL_PATH.LOAN.REQUEST}
                  element={
                    <Suspense fallback={<></>}>
                      <Submission />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.LOAN.RECAPITULATION}
                  element={
                    <Suspense fallback={<></>}>
                      <Recapitulation />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.LOAN.REPORT}
                  element={
                    <Suspense fallback={<></>}>
                      <Report />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.LOAN.SUMMARY}
                  element={
                    <Suspense fallback={<></>}>
                      <DailySummary />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.LOAN.FINISHED}
                  element={
                    <Suspense fallback={<></>}>
                      <CompletedBill />
                    </Suspense>
                  }
                />
                {/* ===== SAVING ===== */}
                <Route
                  path={URL_PATH.SAVING.DETAIL}
                  element={
                    <Suspense fallback={<></>}>
                      <SavingDetail />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.SAVING.REQUEST}
                  element={
                    <Suspense fallback={<></>}>
                      <SavingRequest />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.SAVING.REPORT}
                  element={
                    <Suspense fallback={<></>}>
                      <SavingReport />
                    </Suspense>
                  }
                />
                {/* ===== INSURANCE ===== */}
                <Route
                  path={URL_PATH.INSURANCE.REQUEST}
                  element={
                    <Suspense fallback={<></>}>
                      <InsuranceRequest />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.INSURANCE.REPORT}
                  element={
                    <Suspense fallback={<></>}>
                      <InsuranceReport />
                    </Suspense>
                  }
                />
                {/* ===== OTHER ===== */}
                <Route
                  path={URL_PATH.ANALYTIC}
                  element={
                    <Suspense fallback={<></>}>
                      <Analytic />
                    </Suspense>
                  }
                />
                <Route
                  path={URL_PATH.MY_ACCOUNT}
                  element={
                    <Suspense fallback={<></>}>
                      <Profile />
                    </Suspense>
                  }
                />
                <Route
                  path="/tentang-aplikasi"
                  element={
                    <Suspense fallback={<></>}>
                      <About />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route
                path="/verifikasi-email/:token"
                element={
                  <Suspense fallback={<></>}>
                    <EmailVerification />
                  </Suspense>
                }
              />
              <Route
                path="/*"
                element={
                  <Suspense fallback={<></>}>
                    <NotFound />
                  </Suspense>
                }
              />
            </Routes>
          </AuthContext.Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
