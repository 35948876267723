import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import axios from "../axios";
import LoginImage from "../components/Login/LoginImage";
import LoginForm from "../components/Login/LoginForm";
import TotpForm from "../components/Login/TotpForm";
import Alert from "../components/Alert";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totpData, setTotpData] = useState({});

  const { setAccessToken, setUser, accessToken } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigate("/", { replace: true });
    }

    return () => {
      setIsLoading(false);
      setError(null);
    };
  }, [accessToken, navigate]);

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    setIsLoading(true);
    setError(null);

    try {
      const { data } = await axios({
        method: "POST",
        url: "/users/login/first-step",
        data: { email, password, captchaValue },
      });

      setTotpData(data);
      setIsLoading(false);
    } catch (err) {
      window.grecaptcha.reset();
      setError(err.response.data.message);
      setIsLoading(false);
    }
  };

  const handleSubmitTotp = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const token = data.get("token");

    setIsLoading(true);
    setError(null);

    try {
      const { data } = await axios({
        method: "POST",
        url: "/users/login/second-step",
        data: { totpToken: token, userId: totpData.userId },
      });

      setAccessToken(data.accessToken);
      setUser(data.user);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("user", JSON.stringify(data.user));

      setIsLoading(false);
      navigate("/", { replace: true });
    } catch (err) {
      setError(err.response.data.message);
      setIsLoading(false);

      if (!err.response.data.retry) {
        setTotpData({});
      }
    }
  };

  return (
    <>
      {error && <Alert title="Gagal Masuk" message={error} horizontal="left" />}
      <Grid container sx={{ height: "100vh" }}>
        {totpData && Object.keys(totpData).length ? (
          <TotpForm
            handleSubmit={handleSubmitTotp}
            isLoading={isLoading}
            totpData={totpData}
          />
        ) : (
          <LoginForm
            handleSubmit={handleSubmitLogin}
            isLoading={isLoading}
            setCaptchaValue={setCaptchaValue}
          />
        )}

        <LoginImage />
      </Grid>
    </>
  );
};

export default Login;
