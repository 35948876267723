export const DRAWER_WIDTH = 240;

export const ROLE = {
  OWNER: "owner",
  ADMIN: "admin",
  EMPLOYEE: "employee",
  MEMBER: "member",
};

export const TYPE = {
  EMPLOYEE: "Karyawan",
  MEMBER: "Anggota",
};

export const TIME_MS = {
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 24 * 60 * 1000,
};

export const CITY = {
  PAREPARE: 1,
  MAMASA: 2,
};

export const BILL_TYPE = {
  DAILY: 1,
  MONTHLY: 2,
};

export const BILL_STATUS = {
  ON_GOING: "sedang berlangsung",
  COMPLETED: "selesai",
  EXTENDED: "diperpanjang",
  JAMMED: "macet",
  EXPIRED: "jatuh tempo",
};

export const PAGE = {
  LIMIT: 10,
};

export const CASH_TRANSACTION_TYPE_STR = {
  1: "MASUK",
  2: "KELUAR",
};

export const CASH_TRANSACTION_TYPE = {
  IN: 1,
  OUT: 2,
};

export const CASH_TYPE_STR = {
  1: "Asuransi",
  2: "Simpanan",
};

export const CASH_TYPE = {
  INSURANCE: 1,
  SAVING: 2,
};

export const URL_PATH = {
  ORGANIZATION: {
    SUPER_ADMIN: "/organisasi/super-admin",
    EMPLOYEE: "/organisasi/karyawan",
    MEMBER: "/organisasi/anggota",
    CITY: "/organisasi/kota",
    AREA: "/organisasi/area",
    SERVER_PAYMENT: "/organisasi/pembayaran-server",
  },
  LOAN: {
    REQUEST: "/pinjaman/pengajuan",
    RECAPITULATION: "/pinjaman/rekapitulasi",
    FINISHED: "/pinjaman/selesai",
    LEFT_OVER: "/pinjaman/uang-tinggal",
    REPORT: "/pinjaman/laporan",
    SUMMARY: "/pinjaman/rangkuman",
  },
  SAVING: {
    DETAIL: "/simpanan/detail",
    REQUEST: "/simpanan/pengajuan",
    REPORT: "/simpanan/laporan",
  },
  INSURANCE: {
    REQUEST: "/asuransi/pengajuan",
    REPORT: "/asuransi/laporan",
  },
  ANALYTIC: "/analitik",
  MY_ACCOUNT: "/akun-saya",
};

export const HISTORY_MODAL_TAB = {
  DAILY: 0,
  MONTHLY: 1,
  SAVING: 2,
};

export const EMAIL_PHONE_LOG_STR = {
  1: "Email",
  2: "HP",
  3: "Verifikasi Email",
  4: "Kirim Verifikasi Email",
};
