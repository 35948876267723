import { useContext } from "react";
import AuthContext from "../context/AuthContext";

export const useGetCityName = (opt = {}) => {
  const { user, cities } = useContext(AuthContext);
  const cityId = opt.cityId || user?.cityId;

  const city =
    user && cities.length ? cities.find((city) => city.id === cityId).name : "";

  return city;
};

export const useGetCityOfficeAddress = (opt = {}) => {
  const { user, cities } = useContext(AuthContext);
  const cityId = opt.cityId || user?.cityId;

  const city =
    user && cities.length
      ? cities.find((city) => city.id === cityId).officeAddress
      : "";

  return city;
};
