import { useContext, useState } from "react";
import { Typography, Box, Button, Divider, Chip } from "@mui/material";
import { useQuery } from "react-query";
import { PlayCircleFilled as PlayCircleFilledIcon } from "@mui/icons-material";

import axios from "../axios";
import { formatDate, formatMonth, getBillTypeName } from "../utils";
import AuthContext from "../context/AuthContext";
import LoadingSpinner from "../components/LoadingSpinner";
import HighlightWord from "../components/HighlightWord";
import StartModal from "../components/Home/StartModal";
import CancelModal from "../components/Home/CancelModal";
import StopModal from "../components/Home/StopModal";
import NotificationModal from "../components/Home/NotificationModal";
import ServerPaymentModal from "../components/Home/ServerPaymentModal";
import { BILL_TYPE, ROLE } from "../constants";
import { useGetCityName } from "../hooks/useGetCity";

const Home = () => {
  const [isOpenStartModal, setIsOpenStartModal] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenStopModal, setIsOpenStopModal] = useState(false);
  const [isAllSchedule, setIsAllSchedule] = useState(false);
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(true);
  const [isOpenServerPaymentModal, setIsOpenServerPaymentModal] =
    useState(true);
  const { user, accessToken, billType } = useContext(AuthContext);
  const cityName = useGetCityName();

  const query = "checkHasBeenScheduled";
  const { data, isLoading, isSuccess } = useQuery(
    [query, billType],
    async () => {
      const { data } = await axios({
        method: "GET",
        url: "/schedules",
        headers: {
          accessToken: accessToken || localStorage.getItem("accessToken"),
          billType,
        },
      });

      return data.data;
    },
    { retry: 0 }
  );

  const queryNotif = "getNotif";
  const {
    data: notifData,
    isLoading: isLoadingNotif,
    isSuccess: isSuccessNotif,
  } = useQuery(
    [queryNotif],
    async () => {
      const { data } = await axios({
        method: "GET",
        url: "/notifications/finished-event",
        headers: {
          accessToken: accessToken || localStorage.getItem("accessToken"),
          billType,
        },
      });

      return data.data;
    },
    { retry: 0, enabled: [ROLE.OWNER, ROLE.ADMIN].includes(user?.role) }
  );

  const queryServerPayment = "checkServerPayment";
  const {
    data: serverPaymentData,
    isLoading: isLoadingServerPayment,
    isSuccess: isSuccessServerPayment,
  } = useQuery(
    [queryServerPayment],
    async () => {
      const { data } = await axios({
        method: "GET",
        url: "/server-payments/check",
        headers: {
          accessToken: accessToken || localStorage.getItem("accessToken"),
        },
      });

      return data.isPaid;
    },
    { retry: 0 }
  );

  if (
    !accessToken ||
    isLoading ||
    !user ||
    isLoadingNotif ||
    isLoadingServerPayment
  ) {
    return <LoadingSpinner />;
  }

  const handleStartSchedule = () => {
    setIsOpenStartModal(true);
  };

  const handleStartAllSchedule = () => {
    setIsAllSchedule(true);
    setIsOpenStartModal(true);
  };

  const handleCancelSchedule = () => {
    setIsOpenCancelModal(true);
  };

  const handleStopSchedule = () => {
    setIsOpenStopModal(true);
  };

  const checkIfEnableStart = (data) => {
    if (!data[0]) {
      return true;
    }

    return data[0].isLocked;
  };

  const checkOwner = () => {
    return user.role === ROLE.OWNER;
  };

  const checkOwnerOrAdmin = () => {
    return [ROLE.OWNER, ROLE.ADMIN].includes(user.role);
  };

  return (
    <>
      {isOpenNotificationModal &&
        isSuccessNotif &&
        notifData &&
        Object.values(notifData).some((notif) => notif.length > 0) && (
          <NotificationModal
            isOpen={isOpenNotificationModal}
            setIsOpen={setIsOpenNotificationModal}
            data={notifData}
          />
        )}

      {isOpenServerPaymentModal &&
        isSuccessServerPayment &&
        !serverPaymentData && (
          <ServerPaymentModal
            isOpen={isOpenServerPaymentModal}
            setIsOpen={setIsOpenServerPaymentModal}
            data={notifData}
          />
        )}

      {isOpenStartModal && (
        <StartModal
          isOpen={isOpenStartModal}
          setIsOpen={setIsOpenStartModal}
          query={query}
          isAllSchedule={isAllSchedule}
          setIsAllSchedule={setIsAllSchedule}
        />
      )}

      {isOpenCancelModal && (
        <CancelModal
          isOpen={isOpenCancelModal}
          setIsOpen={setIsOpenCancelModal}
          query={query}
          data={data}
        />
      )}

      {isOpenStopModal && (
        <StopModal
          isOpen={isOpenStopModal}
          setIsOpen={setIsOpenStopModal}
          query={query}
          data={data}
        />
      )}

      <Box
        sx={{ display: "flex", flexDirection: "column", mt: 10, width: "60%" }}
      >
        <Typography variant="h4">
          Selamat datang <HighlightWord>{user.name}</HighlightWord>
        </Typography>
        <Typography variant="h4">
          di Aplikasi KSP Tiga Warna <HighlightWord>{cityName}</HighlightWord>
        </Typography>
        <Typography variant="h4">
          Penagihan <HighlightWord>{getBillTypeName(billType)}</HighlightWord>
        </Typography>
        {isSuccess && checkOwner() && checkIfEnableStart(data) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              startIcon={<PlayCircleFilledIcon />}
              onClick={handleStartAllSchedule}
              fullWidth
              sx={{ marginTop: 10 }}
            >
              Mulai Penagihan {billType === BILL_TYPE.DAILY ? "Hari" : "Bulan"}{" "}
              Ini (Semua Kota)
            </Button>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} light>
              <Chip label="ATAU" />
            </Divider>

            <Button
              variant="outlined"
              startIcon={<PlayCircleFilledIcon />}
              onClick={handleStartSchedule}
              fullWidth
            >
              Mulai Penagihan {billType === BILL_TYPE.DAILY ? "Hari" : "Bulan"}{" "}
              Ini ({cityName})
            </Button>
          </Box>
        )}

        {isSuccess && checkOwnerOrAdmin() && !checkIfEnableStart(data) && (
          <>
            <Box
              sx={{ marginTop: 5, border: "1px solid #1976d2", padding: 10 }}
            >
              <Typography variant="h5">
                Penagihan {getBillTypeName(billType)} berhasil dimulai
              </Typography>
              <Typography variant="h5">
                <HighlightWord>
                  {billType === BILL_TYPE.DAILY
                    ? formatDate(data[0].createdAt)
                    : formatMonth(data[0].createdAt)}
                </HighlightWord>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="error"
                startIcon={<PlayCircleFilledIcon />}
                onClick={handleCancelSchedule}
                sx={{ marginTop: 3, width: "30%", height: 40 }}
                disabled={!checkOwner()}
              >
                Batal
              </Button>

              <Button
                variant="outlined"
                startIcon={<PlayCircleFilledIcon />}
                onClick={handleStopSchedule}
                sx={{ marginTop: 3, width: "30%", height: 40 }}
              >
                Selesai
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Home;
