import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

import AuthContext from "../../context/AuthContext";
import { checkIfViewable } from "../../utils";

const Menu = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClickMenu = (menu) => {
    navigate(menu.path);
  };

  if (!data.children) {
    return checkIfViewable(user, data.role) ? (
      <ListItemButton onClick={() => handleClickMenu(data)}>
        <ListItemIcon>{data.icon}</ListItemIcon>
        <ListItemText primary={data.text} />
      </ListItemButton>
    ) : (
      <Fragment />
    );
  }

  return checkIfViewable(user, data.role) ? (
    <Fragment>
      <ListItemButton onClick={() => setIsOpen(!isOpen)}>
        <ListItemIcon>{data.icon}</ListItemIcon>
        <ListItemText primary={data.text} />
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Divider />
        <List>
          {data.children.map((child, idx) =>
            checkIfViewable(user, child.role) ? (
              <ListItemButton
                sx={{ pl: 4 }}
                key={idx}
                onClick={() => handleClickMenu(child)}
              >
                <ListItemIcon>{child.icon}</ListItemIcon>
                <ListItemText primary={child.text} />
              </ListItemButton>
            ) : (
              <Fragment key={idx} />
            )
          )}
        </List>
        <Divider />
      </Collapse>
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default Menu;
