import { Avatar, TextField, Paper, Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  LockOutlined as LockOutlinedIcon,
  Login as LoginIcon,
} from "@mui/icons-material";

const TotpForm = ({ handleSubmit, isLoading, totpData }) => {
  return (
    <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main", marginBottom: "20px" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ marginBottom: "10px" }}>
          KSP Tiga Warna Parepare
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {totpData && !totpData.isLinked ? (
              <>
                <img src={totpData.url} alt="totp-qrcode" width={250} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="caption">
                    1. Install Google Authenticator di HP Anda.
                  </Typography>
                  <Typography variant="caption">
                    2. Scan barcode di atas.
                  </Typography>
                  <Typography variant="caption">
                    3. Masukkan password Google Authenticator.
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography variant="caption" color="red">
                Cek aplikasi Google Authenticator Anda.
              </Typography>
            )}
          </Box>
          <TextField
            margin="normal"
            required
            fullWidth
            id="token"
            label="password google authenticator"
            name="token"
            type="text"
            autoFocus
          />
          <LoadingButton
            type="submit"
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            fullWidth
            endIcon={<LoginIcon />}
            sx={{ mt: 3, mb: 2 }}
          >
            Masuk
          </LoadingButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default TotpForm;
