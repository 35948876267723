import { useContext } from "react";
import { List, Divider } from "@mui/material";

import Menu from "./Menu";
import AuthContext from "../../context/AuthContext";

const DrawerSideMenu = ({ menus }) => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <Divider />
      <List
        sx={{
          overflow: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: "0.5rem",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(0,0,0,.2)",
            borderRadius: "0.625rem",
            minHeight: "10px",
          },
        }}
      >
        {user && menus.map((menu, idx) => <Menu key={idx} data={menu} />)}
      </List>

      <Divider />
    </>
  );
};

export default DrawerSideMenu;
