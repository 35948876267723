import { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";

import axios from "../../axios";
import Alert from "../Alert";
import AuthContext from "../../context/AuthContext";
import { getBillTypeName } from "../../utils";

const CancelModal = ({ isOpen, setIsOpen, query, data }) => {
  const { billType, accessToken } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (newData) => {
      await axios({
        method: "DELETE",
        url: `/schedules/${data[0].id}`,
        headers: { accessToken, billType },
        data: newData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(query);
      },
    }
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    await mutation.mutateAsync({ password: data.get("password") });
    setIsOpen(false);
  };

  return (
    <>
      {mutation.isError && (
        <Alert
          title={`Gagal Membatalkan Penagihan`}
          message={mutation.error.response.data.message}
        />
      )}

      {mutation.isSuccess && (
        <Alert
          type="success"
          title={`Sukses`}
          message={`Anda berhasil membatalkan penagihan`}
        />
      )}

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          Batalkan Penagihan {getBillTypeName(billType)}
        </DialogTitle>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              required
              fullWidth
              id="password"
              name="password"
              type="password"
              label="Password"
              variant="standard"
              sx={{ mt: 3, width: 400 }}
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="outlined">
              Tutup
            </Button>
            <LoadingButton
              type="submit"
              loading={mutation.isLoading}
              variant="outlined"
            >
              Batalkan Penagihan
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CancelModal;
