import { BILL_TYPE } from "./constants";

export const checkIfViewable = (user, role) => {
  const rank = {
    owner: 1,
    admin: 2,
    employee: 3,
    member: 4,
  };

  return rank[user.role] <= rank[role];
};

export const translateRole = (role) => {
  switch (role) {
    case "admin":
      return "Admin";
    case "employee":
      return "Karyawan";
    default:
      return "";
  }
};

export const formatCurrency = (money) => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  return formatter.format(money).split(",")[0];
};

export const formatDate = (dateInput, dateStyle = "full") => {
  if (!dateInput) {
    dateInput = new Date();
  } else {
    dateInput = new Date(dateInput);
  }

  const formatter = new Intl.DateTimeFormat("id-ID", { dateStyle });
  return formatter.format(dateInput);
};

export const formatMonth = (dateInput) => {
  if (!dateInput) {
    dateInput = new Date();
  } else {
    dateInput = new Date(dateInput);
  }

  const formatter = new Intl.DateTimeFormat("id-ID", {
    month: "long",
    year: "numeric",
  });
  return formatter.format(dateInput);
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const getMonthFromNumber = (number) => {
  const numberToMonth = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  return numberToMonth[number];
};

export const getNumberFromMonth = (month) => {
  const monthToNumber = {
    Januari: 1,
    Februari: 2,
    Maret: 3,
    April: 4,
    Mei: 5,
    Juni: 6,
    Juli: 7,
    Agustus: 8,
    September: 9,
    Oktober: 10,
    November: 11,
    Desember: 12,
  };

  return monthToNumber[month];
};

export const sortByString = (arr, key, sort = "asc") => {
  arr.sort((a, b) => {
    if (sort === "asc") {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (a[key] < b[key]) {
        return 1;
      } else if (a[key] > b[key]) {
        return -1;
      } else {
        return 0;
      }
    }
  });
};

export const getBillTypeName = (billType, opt = {}) => {
  if (opt.removeSuffix || opt.rs) {
    if (billType === BILL_TYPE.DAILY) return "Hari";
    return "Bulan";
  } else {
    if (billType === BILL_TYPE.DAILY) return "Harian";
    return "Bulanan";
  }
};

export const checkSameDay = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);

  return (
    now.getDate() === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  );
};

export const getExpirationDate = (date) => {
  date = new Date(date);
  return date.getDate();
};

export const parseCurrencyFromInput = (val) => {
  let inp = val.split("Rp")[1].trim();
  if (inp.includes(".")) {
    inp = inp
      .split(".")
      .filter((el) => el !== ".")
      .join("");
  }

  const currency = parseInt(inp) || 0;
  return currency;
};
